<template>
    <div style="background: var(--v-component-base) !important">
        <v-divider></v-divider>
        <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="secondary" >groups</v-icon> Teams 
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialog = true, item = { modalType: 'Add', userTeams: [] }" icon><v-icon>add_circle_outline</v-icon></v-btn>
            </v-subheader>
            <v-text-field label="Search" prepend-inner-icon="search" v-model="search" outlined dense clearable hide-details></v-text-field>
        <v-list dense subheader class="my-0 py-0" style="max-height: 28vh; overflow-y: auto">
            <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
            <v-list-item v-for="team in filterTeams" :key="team.id" @click="editItem(team)" style="height: 45px">
            <v-list-item-action>
                <v-icon size="32" :color="team.colour">fiber_manual_record</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>
                    {{ team.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    <v-icon small color="grey">person</v-icon> {{ team.userTeams.length }}
                </v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!loading && filterTeams.length ==0">
            <v-list-item-content class="text-center">
                <span style="color: grey">No teams found.</span>
            </v-list-item-content>
            </v-list-item>
            </v-list>

        <!-- Add/Edit Role dialog -->
        <v-dialog v-model="dialog" width="1500px" persistent :fullscreen="$vuetify.breakpoint.mobile">
        <v-card flat>
            <v-toolbar flat color="transparent">
                <v-toolbar-title>
                    Manage Team
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="secondary" icon @click="saveTeam(), emitEvent()" :loading="savingTeam" :disabled="!item.name" ><v-icon>save</v-icon></v-btn>
                <v-btn color="red" icon @click="removeItem(item), emitEvent()" :loading="deletingTeam" v-if="item.id"><v-icon>delete</v-icon></v-btn>
                <v-btn text @click="closeDialog()"><v-icon>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-subheader style="font-size: 16px" > <v-icon color="secondary" class="mr-2"></v-icon> Details</v-subheader>
                        <v-divider></v-divider>
                        <v-text-field label="Name" v-model="item.name" outlined dense clearable></v-text-field>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Colour
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <el-color-picker v-model="item.colour" id="team-color"></el-color-picker>
                            </v-list-item-action>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-subheader style="font-size: 16px" > <v-icon color="secondary" class="mr-2"></v-icon> Team Members</v-subheader>
                        <v-divider></v-divider>
                        <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchUsers" outlined
                                dense clearable> </v-text-field>
                        <v-list dense subheader style="max-height: 60vh; overflow-y: auto">
                                <v-list-item v-if="(item.userTeams && item.userTeams.length == 0)">
                                    <v-list-item-content>
                                        <v-list-item-title style="color: grey">
                                            No linked users
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(user, index) in filteredUsers" :key="index">
                                    <v-list-item-action>
                                        <v-avatar :color="user.organisationUser.user.avatar ? 'white' : 'secondary'" size="36">
                                            <v-img v-if="user.organisationUser.user.avatar" referrerpolicy="no-referrer"
                                                :src="user.organisationUser.user.avatar" contain></v-img>
                                            <h2 v-else style="color: white; font-weight: normal">{{ user.organisationUser.user.firstname.charAt(0) }}</h2>
                                        </v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ user.organisationUser.user.firstname }} {{ user.organisationUser.user.surname }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action :key="loadingUsersKey">
                                        <v-btn color="red" :loading="loadingUsers.includes(index)" icon @click="removeUserItem(user, index)"><v-icon>block</v-icon></v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                    </v-col>
                    <v-col cols="12" sm="4" :key="loadingUsersKey">
                        <v-subheader style="font-size: 16px" > <v-icon color="secondary" class="mr-2"></v-icon> Remaining Users</v-subheader>
                        <v-divider></v-divider>
                        <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchRemaining" outlined
                                dense clearable> </v-text-field>
                        <v-list dense subheader style="max-height: 60vh; overflow-y: auto">
                                <v-list-item v-if="remainingUsers.length == 0">
                                    <v-list-item-content>
                                        <v-list-item-title style="color: grey">
                                            No remaining users
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(user, index) in remainingUsers" :key="index">
                                    <v-list-item-action>
                                        <v-avatar :color="user.user.avatar ? 'white' : 'secondary'" size="36">
                                            <v-img v-if="user.user.avatar" referrerpolicy="no-referrer"
                                                :src="user.user.avatar" contain></v-img>
                                            <h2 v-else style="color: white; font-weight: normal">{{ user.user.firstname.charAt(0) }}</h2>
                                        </v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ user.user.firstname }} {{ user.user.surname }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <!-- <v-btn color="red" icon @click="removeUser(user, index)"><v-icon>block</v-icon></v-btn> -->
                                        <v-btn color="blue" v-if="!user.added" :loading="userLoading.includes(user.userId)" icon @click="addUser(user)"><v-icon>add</v-icon></v-btn>
                                        <v-btn color="red" v-else icon @click="removeUser(user.data, index)"><v-icon>block</v-icon></v-btn>

                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data: () => ({
        deletingTeam: false,
        dialog: false,
        
        headers: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'name',
            align: 'center',
        }, {
            text: 'Members',
            value: 'userTeams',
            align: 'center',
        },
        ],
        userHeaders: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'organisationUser.user.firstname',
            align: 'center',
        },
        {
            text: 'Surname',
            value: 'organisationUser.user.surname',
            align: 'center',
        }, {
            text: 'Position',
            value: 'organisationUser.position',
            align: 'center',
        },
        ],
        item: {},
        loading: false,
        loadingUsers: [],
        loadingUsersKey: 2000,
        orgUsers: [],
        savingTeam: false,
        search: '',
        searchUsers: null,
        selectedUser: {},
        searchRemaining: null,
        teams: [],
        userLoading: [],
    }),
    props: [ 'teamDialog' ],
    watch: {
        teamDialog() {
                console.log('ga')
        }
    },
    computed: {
        filterOrgUsers() {
            let result = this.orgUsers
            if (this.item && this.item.id) {
                result = result.filter(x => !this.item.userTeams.map(y => y.organisationUserId).includes(x.id))
            }
            return result
        },
        filterTeams(){
            let result = this.teams
            if(this.search){
                result = result.filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()))
            }
            return result
        },
        filteredUsers() {
            let result = this.item.userTeams ?? []
            if (this.searchUsers) {
                result = result.filter(x =>
                    (x.organisationUser.user.firstname.toLowerCase()).includes(this.searchUsers.toLowerCase()) ||
                    (x.organisationUser.user.surname.toLowerCase()).includes(this.searchUsers.toLowerCase())
                )
            }
            return result
        },
        remainingUsers(){
            let result = []
            if(this.item && this.item.userTeams){
                result = [...new Set(this.item.userTeams.map(x=>x.organisationUser.user.id))]
                result = this.orgUsers.filter(x=>!result.includes(x.userId))
            }  
            result.sort((a,b)=>a.user.firstname.localeCompare(b.user.firstname))
            if(this.searchRemaining){
                result = result.filter(x => 
                    (x.user.firstname.toLowerCase()).includes(this.searchRemaining.toLowerCase()) ||
                    (x.user.surname.toLowerCase()).includes(this.searchRemaining.toLowerCase()))
            }
            return result
        }
    },
    created() {
        this.load()
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods: {
        openCreateDialog() {
            this.dialog = true;
            this.item = { modalType: 'Add', userTeams: [] };
        },
        handleKeydown(event) {
            if (event.key === 'Escape' && this.dialog) {
                this.closeDialog();
            }
        },
        closeDialog() {
            this.item = {};
            this.emitEvent()
            this.dialog = false;
        },
        childFunction() {
            this.getTeams()
            this.getOrganisationUsers()
        },
        emitEvent() {
            this.$emit('event', { message: 'Event from Teams component' });
            this.getTeams();
            this.getOrganisationUsers();
        },
        async addUser(userItem) {
            this.userLoading.push(userItem.userId)
            this.userKey ++
            if (this.item.id) {
                let user = await this.$API.createTeamUser({
                    teamId: this.item.id,
                    organisationUserId: userItem.id
                })
                user.organisationUser = userItem
                this.item.userTeams.push(user)
            } else {
                this.item.userTeams.push({
                    organisationUser: userItem
                })
            }
            this.userLoading.splice(this.userLoading.indexOf(userItem.userId), 1)
            this.userKey ++
        },
        editItem(item) {
            this.item = item
            console.log(item)
            this.item.modalType = 'Edit'
            this.dialog = true
        },
        async getOrganisationUsers() {
            this.orgUsers = await this.$API.getOrganisationUsers()
        },
        async getTeams() {
            this.loading = true
            this.teams = await this.$API.getTeams()
            this.loading = false
        },
        async load() {
            this.getTeams()
            this.getOrganisationUsers()
        },

        removeItem(item) {
            this.$confirm('Are you sure you want to delete this team?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.deletingTeam = true
                await this.$API.updateTeam({
                    id: item.id,
                    isActive: false,
                    isDeleted: true
                })
                this.teams.splice(this.teams.findIndex(x=>x.id == item.id), 1)
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
                this.emitEvent();
                this.dialog = false
                this.item = {}
                this.deletingTeam = false
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
                this.deletingTeam = false
            });
        },
        removeUserItem(user, index) {
            this.$confirm(`Are you sure you want to remove ${user.organisationUser.user.firstname} ${user.organisationUser.user.surname} from ${this.item.name}?`, 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.loadingUsers.push(index)
                this.loadingUsersKey ++
                await this.$API.updateTeamUser({
                    id: user.id,
                    isActive: false,
                    isDeleted: true
                })
                this.item.userTeams.splice(index, 1)
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
                this.loadingUsers.splice(this.loadingUsers.indexOf(index), 1)
                this.loadingUsersKey ++
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: 'Delete canceled'
                // });
                this.loadingUsers.splice(this.loadingUsers.indexOf(index), 1)
                this.loadingUsersKey ++

            });
        },
        async saveTeam() {
            this.savingTeam = true
            if (this.item.id) {
                await this.$API.updateTeam(this.item)

            } else {
                let result = await this.$API.createTeam(this.item)
                let userTeams = this.item.userTeams.map(x => ({
                    teamId: result.id,
                    organisationUserId: x.organisationUser.id
                }))
                await this.$API.createTeamUser({
                    batch: userTeams
                })
                this.getTeams()
            }
            this.dialog = false
            this.item = {}
            this.savingTeam = false
        },
    }
}
</script>
