<template>
    <div style="background: var(--v-component-base) !important">
        <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="secondary">manage_accounts</v-icon> Roles
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" @click="addUserRole()" icon><v-icon>add_circle_outline</v-icon></v-btn> -->
        </v-subheader>
        <v-text-field label="Search" prepend-inner-icon="search" v-model="roleSearch" outlined dense clearable
            hide-details></v-text-field>
        <!-- <v-divider class="my-0 py-0"></v-divider> -->
        <v-list dense subheader class="my-0 py-0" style="max-height: 28vh; overflow-y: auto">
            <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
            <v-list-item v-for="role in filterRoles" :key="role.id" @click="editRole(role)" style="height: 60px">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ role.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <v-icon color="grey" class="mr-2" small>groups</v-icon> {{ role.userRoles.length }} User(s)
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        {{ role.description }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!loading && filterRoles.length ==0">
                <v-list-item-content class="text-center">
                    <span style=" color: grey">No roles found.</span>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <!-- <v-data-table dense :items="filterRoles" :loading="loading" :headers="headers" @dblclick:row="editRole" style="cursor: pointer">
        </v-data-table> -->

        <!-- Add/Edit Role dialog -->
        <v-dialog v-model="roleDialog" width="2500px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Manage Role
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn icon color="secondary" @click="saveRole(), emitEvent()" :loading="savingRole"
                        :disabled="!roleItem.name"><v-icon>save</v-icon></v-btn>
                    <v-btn v-if="roleItem.id" icon color="red" :loading="deletingRole"
                        @click="removeRole(roleItem), emitEvent()"><v-icon>delete</v-icon></v-btn>
                    <v-btn icon @click="closeDialog()">
                        <v-icon>
                            close
                        </v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="secondary">info</v-icon>
                                Details</v-subheader>
                            <v-text-field label="Name" outlined dense clearable v-model="roleItem.name"></v-text-field>
                            <v-textarea label="Description" rows="6" outlined dense clearable
                                v-model="roleItem.description"></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-subheader style="font-size: 16px"><v-icon class="mr-2"
                                    color="secondary">admin_panel_settings</v-icon> Permissions</v-subheader>
                            <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchPermissions"
                                outlined dense clearable> </v-text-field>
                            <v-divider></v-divider>
                            <v-list dense subheader style="max-height: 60vh; overflow-y: auto">
                                <v-progress-linear color="primary" v-if="loadingPermissions"
                                    indeterminate></v-progress-linear>
                                <div v-for="(permission, index) in filterPermissions" :key="index">
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-action>
                                            <v-icon color="grey">{{ permission.icon }}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ permission.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn-toggle id="perms-toggle" dense text rounded :key="roleKey"
                                                v-model="permission.access" v-if="!permission.items">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="permission.access = 'none', createRoleAccess(permission, 'none')"
                                                            value="none" small
                                                            :color="permission.access == 'none' ? 'red' : 'grey'">
                                                            <v-icon small
                                                                :color="permission.access == 'none' ? 'red' : 'grey'">block</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">No Access</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="permission.access = 'read', createRoleAccess(permission, 'read')"
                                                            value="read" small
                                                            :color="permission.access == 'read' ? 'blue' : 'grey'">
                                                            <v-icon small
                                                                :color="permission.access == 'read' ? 'blue' : 'grey'">verified</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">Standard</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="permission.access = 'admin', createRoleAccess(permission, 'admin')"
                                                            value="admin" small
                                                            :color="permission.access == 'admin' ? 'green' : 'grey'">
                                                            <v-icon small
                                                                :color="permission.access == 'admin' ? 'green' : 'grey'">security</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">Admin</span>
                                                </v-tooltip>
                                            </v-btn-toggle>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <!-- <v-subheader style="font-size: 16px" ><v-icon class="mr-2" color="grey">{{ permission.icon }}</v-icon> {{ permission.name }}</v-subheader> -->
                                    <v-divider></v-divider>
                                    <v-list-item v-for="subitem in permission.items" :key="subitem.id">
                                        <v-list-item-action>
                                            <v-icon color="greyBase" small>fiber_manual_record</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ subitem.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn-toggle id="perms-toggle" dense text rounded :key="roleKey"
                                                v-model="subitem.access">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="subitem.access = 'none', createRoleAccess(subitem, 'none')"
                                                            value="none" small
                                                            :color="subitem.access == 'none' ? 'red' : 'grey'">
                                                            <v-icon small
                                                                :color="subitem.access == 'none' ? 'red' : 'grey'">block</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">No Access</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="subitem.access = 'read', createRoleAccess(subitem, 'read')"
                                                            value="read" small
                                                            :color="subitem.access == 'read' ? 'blue' : 'grey'">
                                                            <v-icon small
                                                                :color="subitem.access == 'read' ? 'blue' : 'grey'">verified</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">Standard</span>
                                                </v-tooltip>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" outlined
                                                            @click="subitem.access = 'admin', createRoleAccess(subitem, 'admin')"
                                                            value="admin" small
                                                            :color="subitem.access == 'admin' ? 'green' : 'grey'">
                                                            <v-icon small
                                                                :color="subitem.access == 'admin' ? 'green' : 'grey'">security</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span style="font-size: 12px">Admin</span>
                                                </v-tooltip>
                                            </v-btn-toggle>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <!-- <v-list-item  v-if="!permission.items">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ permission.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item> -->
                                </div>
                                <v-list-item v-if="!loadingPermissions && filterPermissions.length == 0">
                                    <v-list-item-content class="text-center">
                                        <span style="color: grey">No permissions found</span>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="secondary">
                                    groups
                                </v-icon>
                                Role Users
                                <v-spacer></v-spacer>
                            </v-subheader>
                            <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchUsers"
                                outlined dense clearable> </v-text-field>
                            <v-divider></v-divider>
                                <v-list dense subheader style="max-height: 60vh; overflow-y: auto" v-if="!addUsers">
                                    <v-list-item v-if="(roleItem.userRoles && roleItem.userRoles.length == 0)">
                                        <v-list-item-content>
                                            <v-list-item-title style="color: grey">
                                                No linked users
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-for="(user, index) in filteredUsers" :key="index">
                                        <v-list-item-action>
                                            <v-avatar :color="user.user.avatar ? 'white' : 'secondary'" size="36">
                                                <v-img v-if="user.user.avatar" referrerpolicy="no-referrer"
                                                    :src="user.user.avatar" contain></v-img>
                                                <h2 v-else style="color: white; font-weight: normal">{{
                                                    user.user.firstname.charAt(0) }}</h2>
                                            </v-avatar>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ user.user.firstname }} {{ user.user.surname }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn color="red" icon
                                                @click="removeUser(user, index)"><v-icon>block</v-icon></v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="secondary">person</v-icon>
                                Remaining Users
                                <v-spacer></v-spacer>
                            </v-subheader>
                            <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchRemainingUsers"
                                outlined dense clearable> </v-text-field>
                            <v-divider></v-divider>
                            <v-list>
                                <v-list-item v-if="remainingUsers.length === 0 && !searchRemainingUsers">
                                    <v-list-item-content>
                                        No Remaining Users
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="remainingUsers.length === 0 && searchRemainingUsers">
                                    <v-list-item-content>
                                        No Remaining Users Found
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-else v-for="(user, index) in remainingUsers" :key="index">
                                    <v-avatar :color="user.user.avatar ? 'white' : 'secondary'" size="36">
                                        <v-img v-if="user.user.avatar" referrerpolicy="no-referrer"
                                            :src="user.user.avatar" contain></v-img>
                                        <h2 v-else style="color: white; font-weight: normal">{{
                                            user.user.firstname.charAt(0) }}</h2>
                                    </v-avatar>
                                    <v-list-item-content style="padding-left: 8px;">
                                        {{ user.user.firstname }} {{ user.user.surname }}
                                    </v-list-item-content>
                                    <v-icon color="blue" @click="addUser2(user)">add</v-icon>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
export default {
    data: () => ({
        searchRemainingUsers: '',
        addUsers: false,
        deletingRole: false,
        
        headers: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'name',
            align: 'center',
        },
        {
            text: 'Description',
            value: 'description',
            align: 'center',
        }
        ],
        loading: false,
        loadingPermissions: false,
        loadingUsers: false,
        permissions: [],
        roles: [],
        roleDialog: false,
        roleItem: {},
        roleSearch: '',
        roleKey: 1000,
        savingRole: false,
        searchPermissions: null,
        searchUsers: null,
        users: [],
        userLoading: [],
        userKey: 4000,
        event: null,
    }),
    props: [ 'trigger' ],
    watch: {
        trigger() {
            this.childFunction();
        },
        searchRemainingUsers: {
            immediate: true,
            handler(val) {
                if (this.searchTimeout) {
                    clearTimeout(this.searchTimeout);
                }
                this.searchTimeout = setTimeout(() => {
                    this.searchTimeout = undefined;
                    // this.gridApi.setGridOption("quickFilterText", val);
                }, 500);
            },
        },
    },
    computed: {
        filterPermissions(){
            let result = this.permissions
            if(this.searchPermissions){
                result = result.filter(x =>
                    (x.name.toLowerCase()).includes(this.searchPermissions.toLowerCase()) ||
                    (x.items && x.items.some(y=>y.name.toLowerCase().includes(this.searchPermissions.toLowerCase())))
                )
            }
            return result
        },
        filterRoles() {
            let result = this.roles
            if (this.roleSearch) {
                result = result.filter(x =>
                    (x.name.toLowerCase()).includes(this.roleSearch.toLowerCase())
                )
            }
            return result
        },
        filteredUsers() {
            let result = this.roleItem.userRoles ?? []
            if (this.searchUsers) {
                result = result.filter(x =>
                    (x.user.firstname.toLowerCase()).includes(this.searchUsers.toLowerCase()) ||
                    (x.user.surname.toLowerCase()).includes(this.searchUsers.toLowerCase())
                )
            }
            return result
        },
        remainingUsers(){
            let result = []
            if(this.roleItem && this.roleItem.userRoles){
                result = [...new Set(this.roleItem.userRoles.map(x=>x.user.id))]
                result = this.users.filter(x=>!result.includes(x.userId))
            }  
            result.sort((a,b)=>a.user.firstname.localeCompare(b.user.firstname))
            if (this.searchRemainingUsers) {
                result = result.filter(x =>
                    (x.user.firstname.toLowerCase()).includes(this.searchRemainingUsers.toLowerCase()) ||
                    (x.user.surname.toLowerCase()).includes(this.searchRemainingUsers.toLowerCase())
                )
            }
            return result
        }
    },
    async created() {
        await this.getRoles()
        await this.getOrganisationUsers()
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    methods: {
        closeDialog() {
            this.roleDialog = false;
            this.permissions = [];
            this.addUsers = false;
            this.emitEvent();
        },
        handleKeydown(event) {
            if (event.key === 'Escape' && this.roleDialog) {
                this.closeDialog();
            }
        },
        async addUser2(user) {
            try {
                if (this.roleItem.id) {
                    let result = await this.$API.createUserRole({
                        batch: [{ userId: user.userId, roleId: this.roleItem.id }]
                    })
                    let data = result[0];
                    data.user = user.user;
                    this.roleItem.userRoles.push(data);
                    user.data = data;
                    this.emitEvent()
                    return this.$message.success('Added User To Role')
                } else {
                    throw new Error('Role Corrupted');
                }
            } catch (e) {
                return this.$message.error(e)
            }
        },
        async childFunction() {
            await this.getRoles()
            await this.getOrganisationUsers()
        },
        emitEvent() {
            this.$emit('event', { message: 'Event from Roles component' });
            this.getRoles()
            this.getOrganisationUsers()
        },
        async addUser(user){
            this.userLoading.push(user.userId)
            if(this.roleItem.id){
                let result = await this.$API.createUserRole({
                batch: [{userId: user.userId, roleId: this.roleItem.id}]
            })
            user.added = true
            let data = result[0]
            data.user = user.user
            this.roleItem.userRoles.push(data)
            user.data = data
            } else {
                user.added = true
            let data = {
                userId: user.userId,
                roleId: null,
                user: user.user
            }
            this.roleItem.userRoles.push(data)
            user.data = data
            }
            this.userLoading = this.userLoading.filter(x=>x!=user.userId)
            this.userKey ++
        },
        addUserRole(){
            this.roleDialog = true
            this.getPermissions()
            this.users = this.users.map(x=>({...x, added: false}))
            this.roleItem = { modalType: 'Add', userRoles: [] }
            this.addUsers = true
        },
        async createRoleAccess(item, type) {
            console.log(item)
            if(this.roleItem.id){
                await this.$API.createRoleAccess({
                roleId: this.roleItem.id,
                route: item.route,
                routeId: item.id,
                routeTabId: null,
                access: type
            })
            }
           
            this.roleKey++
        },
        editRole(role) {
            // console.log(role)
            this.roleItem = role
            this.roleItem.modalType = 'Edit'
            this.getRolePermissions(this.roleItem.id)
            this.roleDialog = true
        },
        async getRoles() {
            this.loading = true
            this.roles = await this.$API.getOrganisationRole()
            this.loading = false
            return
        },
        async getOrganisationUsers() {
            this.loadingUsers = true
            this.users = await this.$API.getOrganisationUsers()
            this.loadingUsers = false
            return;
        },
        async getPermissions() {
            this.loadingPermissions = true
            this.permissions = await this.$API.getOrganisationPermission()
            for(let i=0;i<this.permissions.length;i++){
                this.permissions[i].access = 'read'
                if(this.permissions[i].items){
                    for(let j=0;j<this.permissions[i].items.length;j++){
                        this.permissions[i].items[j].access = 'read'
                    }
                    // this.permissions[i].items.sort((a,b)=>a.name.localeCompare(b.name))
                }
            }
            this.loadingPermissions = false
        },
        async getRolePermissions(id) {
            this.loadingPermissions = true
            this.permissions = await this.$API.getRolePermissions(id)
            console.log(this.permissions)
            this.loadingPermissions = false
        },
        queryRoleSearch(queryString, cb) {
            var links = this.roles;
            var results = queryString ? links.filter(this.createFilter(queryString)) : links;
            // call callback function to return suggestions
            cb(results);
        },
        async removeRole(role) {
            this.$confirm('Are you sure you want to delete this role?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.deletingRole = true
                this.emitEvent();
                await this.$API.updateRole({
                    id: role.id,
                    isActive: false,
                    isDeleted: true
                })
                this.roles.splice(this.roles.indexOf(role), 1)
                this.roleDialog = false
                this.deletingRole = false
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: 'Delete canceled'
                // });
                this.deletingRole = false

            });
        },
        async saveRole() {
            this.savingRole = true
            if (this.roleItem.id) {
                await this.$API.updateRole(this.roleItem)
                this.roleDialog = false
                this.roleItem = {}
                this.getRoles()
            } else {
                let role = await this.$API.createRole(this.roleItem)
                this.roleItem.id = role.id
                let array = [...this.permissions.filter(x=>!x.items)]
                let items = [...this.permissions.filter(x=>x.items).map(x=>x.items).flat()]
                let roles = [...array, ...items].map(x=>({...x, routeId: x.id, roleId: role.id}))
                await this.$API.createBatchRoleAccess({
                    batch: roles
                })
                await this.$API.createUserRole({
                batch: this.roleItem.userRoles.map(x=>({
                    userId: x.userId,
                    roleId: role.id
                }))
            })
                this.roleDialog = false
                this.roleItem = {}
                this.getRoles()
            }
            this.permissions = []
            this.addUsers = false
            this.savingRole = false
        },

        async removeUser(role, index) {
            // let role = user.user.userRoles.find(x => x.roleId == this.item.id)
            if(role.id){
                await this.$API.createUserRole({
                batch: [{ id: role.id, isActive: false, isDeleted: true }]
            })
            let index = this.roleItem.userRoles.findIndex(x => x.id == role.id)
            this.roleItem.userRoles.splice(index, 1)
            this.$message.success('Successfully removed ' + role.user.firstname + ' ' + role.user.surname + ' from role!')
            } else {
                this.roleItem.userRoles.splice(index, 1)
            this.$message.success('Successfully removed ' + role.user.firstname + ' ' + role.user.surname + ' from role!')
            }
        },
    }
}
</script>
